.faq_header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.container.faq_container {
    max-width: 67rem;

    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.faq {
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--color-gold);
    cursor: pointer;
}

.arrow_icon {
    width: 1rem;
}

.question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question h3 {
    font-size: 1.3rem;
}

.answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.answer p {
    padding-top: 1rem;
    line-height: 1.5;
    font-size: 1.1rem;
    white-space: pre-line;
}

.faq.active .answer {
    max-height: 300px;
    animation: fade 0.7s ease-in;
}

.arrow.rotated {
    transform: rotate(180deg);
}

.arrow {
    transition: transform 0.5s ease-in;
}

@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .question h3 {
        font-size: 1.1rem;
    }

    .answer p {
        font-size: 1rem;
    }
}