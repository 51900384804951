.blog_header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.container.blog_container {
    max-width: 67rem;
    overflow: hidden;
}

.blog_container .swiper-pagination {
    bottom: 1px !important;
}

.bigSwiper, .smallSwiper {
    padding: 6px;
}

.bigSwiper .swiper-slide, .smallSwiper .swiper-slide {
    border-radius: 2rem;
    border: 2px solid transparent;
    text-align: center;
    background: var(--color-white);
    height: 420px;
    max-height: 450px;
    overflow: hidden;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.smallSwiper {
    display: none;
}

.swiper-slide .blog_img {
    display: block;
    width: 100%;
    aspect-ratio: 1/0.7;
    object-fit: cover;
    margin: 0 auto;
    /*padding-top: 1rem;*/
}

.swiper-slide .title {
    margin-top: 2rem;
}

.swiper-slide .text {
    margin: 0 1rem 1rem 1rem;
}

.swiper-slide .text span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient: vertical;
    text-align: justify;
}


.swiper-slide .text .blog_link:hover {
    font-size: 1.2rem;
}

.swiper-slide .text .blog_link {
    color: var(--color-read-more);
    display: block;
    text-align: left;
    line-height: 2rem;
    font-size: 1.1rem;
    transition: font-size 0.5s ease-in-out;
    font-weight: bold;
}

.swiper-slide .text a .icon {
    padding-left: 0.1rem;
    vertical-align: middle;
}

/*
FOR MEDIUM DEVICES (TABLETS)
*/
@media screen and (max-width: 1024px) {
    .bigSwiper .swiper-slide {
        height: 350px;
        line-height: 1.3;
    }

    .swiper-slide .title h3 {
        font-size: 1rem;
        padding-bottom: 0.5rem;
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .bigSwiper {
        display: none;
    }

    .smallSwiper {
        display: block;
    }

    .smallSwiper .swiper-slide {
        line-height: 1.4;
    }

    .swiper-slide .blog_img {
        aspect-ratio: 1/0.6;
    }
}