#blog_overview_page {
    margin-top: 1rem;
}

.blog_overview_page_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2%;
    row-gap: 1rem;
    place-items: center;
    height: max-content;
}

.overview_page_item {
    border: 2px solid transparent;
    text-align: center;
    background: var(--color-white);
    max-height: 400px;
    max-width: 300px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.overview_page_item .blog_img {
    display: block;
    width: 100%;
    aspect-ratio: 1/0.6;
    object-fit: cover;
    margin: 0 auto;
}

.overview_page_item .title {
    margin-top: 2rem;
}

.overview_page_item .text {
    margin: 0 1rem 1rem 1rem;
}

.overview_page_item .text span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient: vertical;
    text-align: justify;
}

.overview_page_item .text .blog_link:hover {
    font-size: 1.2rem;
}

.overview_page_item .text .blog_link {
    color: var(--color-read-more);
    display: block;
    text-align: left;
    line-height: 2rem;
    font-size: 1.1rem;
    transition: font-size 0.5s ease-in-out;
    font-weight: bold;
}

.overview_page_item .text a .icon {
    padding-left: 0.1rem;
    vertical-align: middle;
}

/*
  FOR MEDIUM DEVICES (TABLETS)
 */
@media screen and (max-width: 1000px) {
    .blog_overview_page_container {
        grid-template-columns: 1fr 1fr;
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .blog_overview_page_container {
        grid-template-columns: 1fr;
    }
}