#about {
    margin-top: 2rem;
}

.about_header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.about_container {
    display: grid;
    grid-template-columns: 35% 60%;
    gap: 5%;
    place-items: center;
    max-width: 75rem;
}

.about_me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    /*background: linear-gradient(45deg, transparent, var(--color-bg-about), transparent);*/
    background-image: url("../../images/image-22-01-23-12-29.png");
    background-repeat: no-repeat;
    background-size: cover;
    /*place-items: center;*/
    /*margin-top: 3rem;*/
    overflow: hidden;
}

.about_me_image {
    width: 18rem;
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(5deg);
    transition: var(--transition);
}

.about_me_image:hover {
    transform:  rotate(0deg);
}

.about_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about_card {
    border: 1px solid transparent;
    border-radius: 3rem 1rem;
    padding: 2rem 0.5rem;
    text-align: center;
    color: var(--color-text);
    transition: var(--transition);
}

.about_card:hover {
    background: transparent;
    border-color: var(--color-light);
    cursor: default;
}

.about_icon {
    color: var(--color-primary);
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.about_card h5 {
    font-size: 0.95rem;
}

.small {
    font-size: 0.7rem;
}

.about_me_text {
    margin: 0 auto;
}

.about_me_list {
    list-style-type: disc;
    display: list-item;
    margin-left: 2em;
}

/*
  FOR MEDIUM DEVICES (TABLETS)
 */
@media screen and (max-width: 1024px) {
    .about_container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about_me {
        display: none;
    }

    .about_me_text {
        font-size: 1.1rem;
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .about_me {
        display: none;
    }

    .about_cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about_content {
        text-align: center;
    }
}