.portfolio_header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.container.portfolio_container {
    max-width: 67rem;
    overflow: hidden;
}

.portfolio_container .swiper-pagination {
    bottom: 20px !important;
}

.portfolio_container .swiper-pagination-bullet-active {
    background-color: var(--color-white) !important;
}

.project_design_img {
    width: 60rem;
    overflow: hidden;
    /*aspect-ratio: 1/0.65;*/
    margin: 0 auto 1rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.project_design_img:hover {
    border-color: var(--color-light);
}

.portfolio_item {
    background: var(--color-bg-experience);
    text-align: center;
    padding: 1rem;
    border-radius: 2rem;
    user-select: none;
}

.design_description {
    color: var(--color-bg);
    font-weight: 500;
    display: block;
    width: 80%;
    margin: 2rem auto 0;
}

.sketch {
    aspect-ratio: 1/0.8;
}

@media screen and (max-width: 1200px) {
    .project_design_img {
        width: 52rem;
    }
}

/*
  FOR MEDIUM DEVICES (TABLETS)
 */
@media screen and (max-width: 1024px) {
    .project_design_img {
        width: 38rem;
    }
    .portfolio_item .swiper-button-prev, .portfolio_item .swiper-button-next {
        display: none !important;
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .container.portfolio_container {
        width: var(--container-width-sm);
    }
    .design_description {
        width: var(--container-width-sm);
    }
    .project_design_img {
        width: 19.5rem;
    }
}
