.steps_header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.steps_container {
    max-width: 67rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.steps_wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    justify-content: space-between;
}

.step_block {
    text-align: center;
    position: relative;
}

.circle.active:after {
    content: '';
    width: 1rem;
    height: 3px;
    position: absolute;
    bottom: -1rem;
    left: 1.4rem;
    background-color: var(--color-light);
    transform: rotate(90deg);
}

.circle {
    display: table-cell;
    vertical-align: middle;
    margin: auto 0;
    width: 4rem;
    height: 4rem;
    background-color: var(--color-light);
    color: var(--color-text);
    border-radius: 50%;
    border: 2px solid transparent;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.circle_wrapper {
    font-size: 1.5rem;
    font-weight: bold;
}

.circle.active {
    background: transparent;
    border-color: var(--color-light);
    animation: scaleup 0.3s linear;
    animation-fill-mode: forwards;
}

.circle:hover {
    background: transparent;
    border-color: var(--color-light);
    animation: scaleup 0.3s linear;
    animation-fill-mode: forwards;
    animation-delay: 0.1s;
}

.step_description {
    border-radius: 0.5rem;
    border: 2px solid var(--color-light);
    padding: 0.5rem 0.5rem;
    max-height: 300px;
    height: 13rem;
    overflow: scroll;
    background: var(--color-bg-experience);
    font-size: 1.2rem;
    color: var(--color-bg);
    white-space: pre-line;
    line-height: 1.4;
}

.step_title {
    text-align: center;
    margin-bottom: 1rem;
}

.step_description0 {
    animation: fade0 0.5s ease-in;
}
.step_description1 {
    animation: fade1 0.5s ease-in;
}
.step_description2 {
    animation: fade2 0.5s ease-in;
}
.step_description3 {
    animation: fade3 0.5s ease-in;
}
.step_description4 {
    animation: fade4 0.5s ease-in;
}
.step_description5 {
    animation: fade5 0.5s ease-in;
}
.step_description6 {
    animation: fade6 0.5s ease-in;
}
.step_description7 {
    animation: fade7 0.5s ease-in;
}

@keyframes scaleup {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.15);
    }
}

@keyframes fade0 {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fade1 {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fade2 {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fade3 {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fade4 {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fade5 {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fade6 {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fade7 {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

/*
FOR MEDIUM DEVICES (TABLETS)
*/
@media screen and (max-width: 1024px) {
    .circle {
        width: 3.5rem;
        height: 3.5rem;
    }

    .circle.active:after {
        left: 1.1rem;
    }

    .step_description {
        height: 14rem;
    }
}


/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 650px) {
    .circle {
        width: 2.5rem;
        height: 2.5rem;
    }

    .circle.active:after {
        left: 25%;
    }

    .step_description {
        height: 20rem;
        background-size: cover;
        line-height: 1.3;
    }
}