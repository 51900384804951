.services_header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.services_container {
    max-width: 67rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

.service {
    background: var(--color-bg-experience);
    border-radius: 1rem;
    /*border: 1px solid var(--color-black);*/
    height: fit-content;
}

.service_head {
    /*background: var(--color-head);*/
    padding: 1rem 1rem 0.5rem;
}

.service_head h3 {
    color: var(--color-bg);
    font-size: 1.2rem;
    text-align: left;
}

.service_list {
    padding: 1rem;
}

.service_list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.service_list_icon {
    color: var(--color-gold);
    margin-top: 3px;
    width: 1rem;
}

.service_text {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 3;
    justify-content: flex-end;
}

.service_list span {
    font-size: 0.9rem;
    color: var(--color-bg);
}

.price {
    font-weight: 500;
}

.dotted {
    background-image: linear-gradient(to right,var(--color-white) 20%,rgba(0,0,0,0) 20%);
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    margin: 0 0.5rem;
    flex-grow: 1;
    top: -0.5rem;
    position: relative;
}

/*
  FOR MEDIUM DEVICES (TABLETS)
 */
@media screen and (max-width: 1024px) {
    .services_container {
        gap: 2rem;
    }

    .service {
        height: auto;
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .services_container {
        gap: 1.5rem;
    }

    .price {
        align-self: flex-end;
    }

    .dotted {
        align-self: flex-end;
    }
}