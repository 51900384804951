.experience_header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.experience_container {
    max-width: 67rem;
    display: block;
}

.experience_container > div {
    background: var(--color-bg-experience);
    padding: 2.4rem 3rem;
    border-radius: 1rem;
    border: 1px solid transparent;
}

.experience_content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 2rem;
}

.experience_details {
    display: flex;
    gap: 1rem;
    color: var(--color-bg);
}

.experience_details_icon {
    margin-top: 6px;
    color: var(--color-gold);
    width: 2rem;
}

/*
  FOR MEDIUM DEVICES (TABLETS)
 */
@media screen and (max-width: 1024px) {
    .experience_container > div {
        /*width: 100%;*/
        padding: 2rem;
        margin: 0 auto;
    }

    .experience_content {
        padding: 1rem;
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .experience_container > div {
        grid-template-columns: 1fr 1fr;
        padding: 2rem 1rem;
    }
    .experience_details {
        gap: 0.5rem;
    }
}