nav {
    background: rgba(0, 0, 0, 0.25);
    opacity: 0.8;
    width: max-content;
    display: flex;
    padding: 0.3rem 1rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.5rem;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav:hover {
    background: rgba(0, 0, 0, 0.35);
    opacity: 1;
}

nav a {
    background: transparent;
    padding: 0.7rem;
    display: flex;
    font-size: 1.1rem;
    border-radius: 50%;
}

nav a:hover {
    background: var(--color-gold);
}

nav a.active {
    background: var(--color-gold-light);
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    nav {
        width: 100%;
        justify-content: center;
        gap: 0.3rem;
        bottom: 0;
        opacity: 0.9;
        border-radius: 0;
    }
}