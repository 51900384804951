.top_nav {
    display: flex;
    background-color: var(--color-bg);
    height: 40px;
    max-height: 40px;
    width: 100%;
    font-weight: bold;
    border-bottom: 1px solid black;
}

.nav_left_side {
    display: flex;
    flex: 10%;
    justify-content: flex-start;
    align-items: center;
    background: transparent;

}

.nav_right_side {
    display: flex;
    flex: 90%;
    justify-content: right;
    align-items: center;
}

.nav_right_side .links {
    display: flex;
    list-style-type: none;
    padding: 10px;
}

.nav_right_side .links .link {
    display: flex;
    padding: 0 2rem;
}

.menu-item {
    text-decoration: none;
    color: var(--color-black);
    font-size: 1.3rem;
    margin-right: 15px;
    padding: 2px;
    border-radius: 5px;
}

.menu-item:hover {
    color: var(--color-gold);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}

.nav_icon {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: var(--color-black);
    margin-left: 20px;
    padding: 0.2rem;
    cursor: pointer;
}

.nav_icon:hover {
    color: var(--color-gold);
}

.nav_links_icon {
    font-size: 2rem;
    color: var(--color-black);
    cursor: pointer;
}

.nav_btn {
    display: none;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    margin-right: 1rem;
    cursor: pointer;
}

.nav_links_icon:hover {
    color: var(--color-gold);
}


/*
  FOR MEDIUM DEVICES (TABLETS)
 */
@media screen and (max-width: 1024px) {
    .menu-item {
        font-size: 1.2rem;
        padding: 4px;
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 650px) {
    .nav_right_side .links {
        display: none;
    }

    .nav_right_side .nav_btn {
        display: flex;
    }

    .nav_right_side .hidden {
        display: flex;
        position: absolute;
        left: 0;
        top: 40px;
        width: 100%;
        background-color: var(--color-bg);
        z-index: 10;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        border-bottom: 4px solid white;
        max-height: 300px;
    }

    .menu-item {
        font-size: 1.3rem;
    }
}