@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Work+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

@font-face {
  font-family: Brittany;
  src: url(./data/BrittanySignature.ttf);
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #F6F4F2;
  --color-bg-gradient: linear-gradient(to bottom, #c7bdb3, rgba(141, 112, 85, 0.78));
  --color-bg-faq-button-line: #C6AD8F;
  --color-gold: #C6AD8F;
  --color-gold-light: rgba(198, 173, 143, 0.7);
  --color-bg-experience: rgba(66, 86, 100, 1);
  --color-bg-footer: rgba(66, 86, 100, 1);
  --color-bg-hover: rgba(66, 86, 100, 0.8);
  --color-primary: #000000;
  --color-primary-variant: rgba(66, 86, 100, 0.6);
  --color-white: #fff;
  --color-less-white: #afafaf;
  --color-read-more: #744f10;
  --color-black: black;
  --color-text: #3c281a;
  --color-light: rgba(66, 86, 100, 0.6);
  --color-bg-about: rgb(67, 75, 94);
  --color-disabled: rgba(67, 75, 94, 0.4);
  --transition: all 400ms ease;

  --container-width-lg: 80%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: 'Forum', 'Poppins', 'Work Sans', sans-serif;
  background: var(--color-bg);
  color: var(--color-black);
  line-height: 1.7;
  /*background-image: url("../src/images/background/black-linen-2.png");*/
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

.h1, .h2, .h3, .h4, .h5 {
  font-weight: 500;
}

h1 {
  font-family: Brittany;
  font-size: 4rem;
}

.letter_space {
  letter-spacing: 0.1rem;
}

section {
  margin-top: 8rem;
  letter-spacing: 0.1rem;
}

section > h1 {
  font-family: Brittany;
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

section > h1, section > h2,  section > h3, section > h4, section > h5 {
  text-align: center;
  color: var(--color-text);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-text);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid transparent;
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-bg-hover);
  border-color: var(--color-light);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
}

.btn-primary {
  background: var(--color-bg-experience);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

small {
  color: var(--color-light);
}

/*
  FOR MEDIUM DEVICES (TABLETS)
 */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 4rem;
  }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 1rem;
  }
}