footer {
    background: var(--color-bg-footer);
    padding: 2rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 5rem;
}

footer a {
    color: var(--color-bg);
}

footer a:hover {
    color: var(--color-black);
}

.footer_logo {
    font-family: Brittany;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: inline-block;
}

.footer_copyright {
    margin-bottom: 2rem;
}

.footer_copyright > small {
    color: var(--color-bg);
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .footer_logo {
        font-size: 1.5rem;
    }
}