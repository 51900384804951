.contact_header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.container.contact_container {
    width: 67rem;
    display: grid;
    grid-template-columns: 30% 60%;
    gap: 10%;
    margin-bottom: 2rem;
}

.contact_options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact_option > h4 {
    color: var(--color-bg);
}

.contact_option {
    background: var(--color-bg-experience);
    padding: 0.8rem;
    border-radius: 0.4rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover {
    background: var(--color-bg-hover);
    border-color: var(--color-light);
}

.contact_option_icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--color-white);
}

.contact_option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1.2rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

input, textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-light);
    resize: none;
}

/*
FOR MEDIUM DEVICES (TABLETS)
*/
@media screen and (max-width: 1024px) {
    .container.contact_container {
        width: var(--container-width-md);
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .container.contact_container {
        width: var(--container-width-sm);
    }
}