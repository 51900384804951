#blogPage {
    margin-top: 1rem;
}

.blogpage_container {
    display: grid;
    grid-template-columns: 35% 60%;
    column-gap: 5%;
    place-items: center;
}

.blogpage_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.blogpage_image {
    border-radius: 2rem;
    overflow: hidden;
}

.blogpage_content {
    margin-top: 1rem;
}

.blogpage_text {
    margin: 0 auto;
    color: var(--color-text);
    white-space: pre-line;
    text-align: justify;
}

.btn-blog {
    width: max-content;
    display: inline-block;
    background: var(--color-light);
    color: var(--color-white);
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid transparent;
    transition: var(--transition);
}

.btn-blog:hover {
    background: var(--color-bg-hover);
    border-color: var(--color-light);
}

.blogpage_header .disabled {
    cursor: not-allowed;
    background: var(--color-disabled);
    border-color: var(--color-disabled);
}

.blogpage_header .disabled:hover {
    background: var(--color-disabled);
}

.blogpage_icon {
    padding-left: 0.1rem;
    vertical-align: middle;
}

/*
  FOR MEDIUM DEVICES (TABLETS)
 */
@media screen and (max-width: 1024px) {
    .blogpage_container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .blogpage_image {
        width: 70%;
    }

    .blogpage_content {
        margin-top: 2rem;
    }

    .blogpage_text {
        font-size: 1.1rem;
    }
}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .blogpage_image {
        width: 100%;
    }
}