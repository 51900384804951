#price_page {
    margin-top: 2rem;
}

#price_page .swiper-pagination {
    bottom: 0 !important;
}

.priceSwiper {
    max-width: 30%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 2rem;
    overflow: hidden;
}


/*
FOR MEDIUM DEVICES (LAPTOPS)
*/
@media screen and (max-width: 1300px) {
    .priceSwiper {
        max-width: 40%;
    }
}

/*
FOR MEDIUM DEVICES (TABLETS)
*/
@media screen and (max-width: 1024px) {
    .priceSwiper {
        max-width: 70%;
    }
}


/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .priceSwiper {
        max-width: 90%;
    }
}