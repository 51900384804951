header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
    background: var(--color-bg-about);
}

.header_container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 5%;
    place-items: center;
    justify-content: center;
    max-width: 70rem;
    color: var(--color-bg);
}

.header_buttons {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 10rem;
}

.header_socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.me {
    width: 22rem;
    border: 1px solid black;
}

.header_image {
    overflow: hidden;
}

/*.header_text {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    gap: 0.8rem;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    margin-top: 25rem;*/
/*}*/

.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 15rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}

@keyframes img_animate {
    0% {
        border-radius: 50% 40% 30% 70%/50% 30% 70% 50%;
    }
    50% {
        border-radius: 30% 85% 80% 30%/40% 70% 50% 40%;
    }
    100% {
        border-radius: 50% 40% 30% 70%/50% 30% 70% 50%;
    }
}

/*
  FOR MEDIUM DEVICES (TABLETS)
 */
@media screen and (max-width: 1024px) {
    h1 {
        font-size: 3rem !important;
    }

}

/*
  FOR SMALL DEVICES (MOBILE PHONES)
 */
@media screen and (max-width: 600px) {
    .header_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .scroll_down, .header_socials {
        display: none;
    }
    .me {
        margin-top: 1rem;
        width: 14rem;
    }
    .header_text {
        text-align: center;
    }
    h1 {
        font-size: 2rem !important;
    }
}